'use client';
import React from 'react';
import { Row } from 'antd';
import cn from 'classnames';

import VideoPlay from '../VideoIframe';
import { VideoBlockFragment } from '@/@types/generated/sitecore';
import { withFullPath } from '@/utils/utils';

type Props = {
    content: VideoBlockFragment;
    className?: string;
};

const Video = (props: Props) => {
    const { className, content } = props;

    return (
        <Row className='flex justify-center' data-aos='fade-right' data-aos-delay='500'>
            <VideoPlay
                className={cn('mt-6 xmd:w-[768px] w-full', className)}
                localHref={typeof window !== 'undefined' ? window.location.href : ''}
                videoLink={content.videoSource?.value}
                videoAutoPlay={false}
                videoPoster={withFullPath(content.videoThumbnail?.src)}
            />
            {/* <div className='my-2 xmd:w-[768px] w-ful'>{content.videoCaption?.value}</div> */}
        </Row>
    );
};

export default Video;

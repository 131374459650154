'use client';

import { Image, ImageProps } from 'antd';
import cn from 'classnames';
import { ImagePreviewType } from 'rc-image/lib/Image';
import {
    JSXElementConstructor,
    ReactElement,
    ReactNode,
    useState,
    useEffect,
    cloneElement,
} from 'react';
import styles from './index.module.scss';
import { withFullPath } from '@/utils/utils';

export interface imageProps {
    setting?: ImageProps;
    canPreview?: boolean;
    mediaOption?: {
        mediaType?: String;
        videoSrc?: string;
        videoOptions?: any;
        mediaCaption?: string;
    };
    previewProps?: ImagePreviewType;
    wrapClass?: string;
}

interface imageGroupProps extends imageProps {
    children?: ReactNode;
    imageList?: {
        id?: number;
        src?: string;
        mediaType?: String;
        videoSrc?: string;
        mediaCaption?: string;
    }[];
    previewProps?: ImagePreviewType;
    total?: number;
    currentPreviewIndex?: number;
    handelVisibleChange?: (visible: boolean) => void;
}

const ImageRender = (params: {
    originalNode: ReactElement<any, string | JSXElementConstructor<any>>;
    currentMedia: {
        id?: number;
        src?: string;
        mediaType?: String;
        videoSrc?: string;
        videoOptions?: any;
        mediaCaption?: string;
    };
    current?: number;
    total?: number;
}) => {
    const { originalNode, currentMedia, current = 0, total } = params;

    return (
        <div className={cn(styles.imagePreviewWrap)}>
            {originalNode}
            <div className='w-full text-white flex justify-between px-3 py-1 gap-5 absolute top-[100%]'>
                <div className='text-left max-h-[6vh] overflow-y-auto'>
                    {currentMedia.mediaCaption || ''}
                </div>
                {total && <div className='whitespace-nowrap'>{`${current + 1} / ${total}`}</div>}
            </div>
        </div>
    );
};
export const ImagePreviewGroup = ({
    setting,
    children,
    canPreview = true,
    imageList,
    previewProps = {},
    currentPreviewIndex,
    handelVisibleChange,
}: imageGroupProps) => {
    const [visible, setVisible] = useState(false);
    const [currentIndex, setCurrent] = useState<number | undefined>(undefined);

    useEffect(() => {
        currentPreviewIndex !== undefined && setCurrent(currentPreviewIndex);
    }, [currentPreviewIndex]);

    return (
        <Image.PreviewGroup
            preview={
                canPreview && {
                    visible,
                    current: currentIndex,
                    rootClassName: styles.imageGroupWrap,
                    movable: false,
                    minScale: 1,
                    maxScale: 1,
                    toolbarRender: () => null,
                    imageRender: (originalNode, info) => {
                        const Node = cloneElement(originalNode, {
                            src: imageList
                                ? withFullPath(imageList[info.current]?.src)
                                : originalNode?.props?.src,
                        });
                        return (
                            <ImageRender
                                originalNode={Node}
                                currentMedia={(imageList && imageList[info.current]) || {}}
                                current={info.current}
                                total={imageList && imageList.length}
                            />
                        );
                    },
                    onVisibleChange: (visible) => {
                        setVisible(visible);
                        handelVisibleChange && handelVisibleChange(visible);
                    },
                    onChange:
                        currentPreviewIndex !== undefined
                            ? (current) => {
                                  setCurrent(current);
                              }
                            : undefined,
                    ...previewProps,
                }
            }
            {...setting}
        >
            {children}
        </Image.PreviewGroup>
    );
};

const ImagePreview = ({
    setting,
    canPreview = false,
    mediaOption,
    previewProps,
    wrapClass,
}: imageProps) => {
    return (
        <div
            className={cn(
                'w-full relative',
                mediaOption?.mediaType === 'Video' && 'text-center',
                wrapClass,
            )}
        >
            <Image
                alt=''
                preview={
                    canPreview && {
                        mask: null,
                        movable: false,
                        minScale: 1,
                        maxScale: 1,
                        toolbarRender: () => null,
                        ...previewProps,
                    }
                }
                {...setting}
                rootClassName={cn(styles.imageRootClass, setting?.rootClassName)}
                src={withFullPath(setting?.src)}
            />
        </div>
    );
};

export default ImagePreview;

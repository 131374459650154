'use client';
import { memo, useEffect } from 'react';
import cn from 'classnames';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import styles from './index.module.scss';
import withTheme from '@/theme';
import { WATrackerClickEvn } from '@/utils/wa';

type Props = {
    content: { richTextContent?: { rendered?: string }; template?: { name: string } };
    id?: string;
    className?: string;
    page?: string;
};

// const handleTableCSS = () => {
//     let timer = setTimeout(() => {
//         const table = document?.querySelector('table');
//         if (table) {
//             table.style.width = '100%';
//         }

//         const rows = table?.getElementsByTagName('tr');
//         if (rows) {
//             for (let i = 0; i < rows?.length; i++) {
//                 rows[i].style.border = '1px solid #999999';

//                 const cells = rows[i].getElementsByTagName('td');
//                 for (let j = 0; j < cells.length; j++) {
//                     cells[j].style.border = '1px solid #999999';
//                     cells[j].style.textAlign = 'center';
//                 }
//             }
//         }
//         // timer && clearTimeout(timer)
//     }, 1000);
// };

const RichText = memo(
    (props: Props) => {
        const {
            content: { richTextContent },
            className,
            id,
        } = props;

        const globalStore = useGlobalStore();

        const coverImgClick = () => {
            const newsImgList: NodeListOf<HTMLAnchorElement> =
                document.querySelectorAll('a.group1') || [];
            newsImgList.forEach((item, index) => {
                item.onclick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    globalStore.setNewsImgGroupVisible(true);
                    globalStore.setNewsImgIndex(index);
                };
            });
        };

        const newsImgGroup = () => {
            setTimeout(() => {
                if (typeof document !== undefined) {
                    const newsImgList: NodeListOf<HTMLAnchorElement> =
                        document.querySelectorAll('a.group1') || [];

                    const list = Array.from(newsImgList).map((item, index) => {
                        const imgItem = item?.querySelector('img');
                        return {
                            id: index,
                            src: item?.href || imgItem?.src || '',
                            mediaCaption: item?.title || '',
                        };
                    });

                    globalStore.setNewsImgGroup(list);
                    coverImgClick();
                }
            }, 300);
        };

        const getWaStandardTag = () => {
            setTimeout(() => {
                if (typeof document !== undefined) {
                    const waNonStandardTagNode = document.querySelectorAll('.waNonStandardTag');
                    waNonStandardTagNode?.forEach((item: any) => {
                        item.onclick = (e: any) => {
                            const eventID = item.getAttribute('data-wa');
                            WATrackerClickEvn(eventID);
                        };
                    });
                }
            }, 300);
        };

        useEffect(() => {
            if (props.page == 'news') {
                // handleTableCSS();
                newsImgGroup();
            }
            getWaStandardTag();
        }, [richTextContent, props.page]);

        useEffect(() => {
            coverImgClick();
        }, [globalStore.newsImgGroup, globalStore.newsImgGroupVisible]);

        return withTheme(
            <div
                id={id}
                className={cn(styles.richText, className)}
                dangerouslySetInnerHTML={{
                    __html: richTextContent?.rendered ?? '',
                }}
            ></div>,
            {
                token: {
                    fontFamily: globalStore.fontFamily,
                },
            },
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.content.richTextContent === nextProps.content.richTextContent &&
            prevProps.page === nextProps.page
        );
    },
);
RichText.displayName = 'RichText';
export default RichText;
